//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {maxLength, minLength, required} from "vuelidate/lib/validators";
//import axios from "axios";

export default {
  name: "EditPrinter",
  components: {},
  data(){
    return {
      printerWorkSchedule:[],
      printerData: {
        code: "",
        ip: "",
        login: "",
        password: "",
        port: "",
        serial_number: "",
        notes: ""
      },
      submitted: false,
    }
  },
  props: {
    printer: Object,
    submitPrinter: Number
  },
  watch: {
    printer: function(prnt){
      let obj = {};
      for(let key in prnt){
        obj[key] = prnt[key];
      }
      this.printerData = obj;
    },
    submitPrinter: function(val){
      if(val > 0){
        this.submitEditPrinter();
      }
    }
  },
  computed: {

  },
  validations: {
    printerData: {
      serial_number: { required, minLength: minLength(3), maxLength: maxLength(35) },
      ip: { required, minLength: minLength(10), maxLength: maxLength(20) },
      port: { required, minLength: minLength(3), maxLength: maxLength(6) },
      code: { required, minLength: minLength(6), maxLength: maxLength(6) },
      login: { required, minLength: minLength(3), maxLength: maxLength(65) },
      password: { required, minLength: minLength(3), maxLength: maxLength(65) },
      notes: { maxLength: maxLength(350) }
    }
  },
  methods: {
    setWorkSchedule(val){
      this.printerData.printerWorkSchedule = val;
    },
    setPrice(val){
      this.printerData.printerPrice = val;
    },
    submitEditPrinter(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.printerData.$touch();
      if(!this.$v.printerData.$invalid){
        this.$emit('submitEditPrinter', this.printerData);
      }
    },
  },
  created() {

  }

};
