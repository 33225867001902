//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Popup from "@/components/Popup";
import CopyArrayAndObject from "@/components/libraries/CopyArrayAndObject";
import EditPrinterSis from '../../../components/management/printers/EditPrinterSis.vue';

const copyArrObj = new CopyArrayAndObject();

/**
 * Printers component
 */
export default {
  components: {
    Popup,
    Layout,
    PageHeader,
    EditPrinterSis,
  },
  data() {
    return {
      printers: [],
      searchString: '',
      editPrinter: {},
      editPrinterPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.save'),
        actionClass: "btn-success"
      },
      submitted: false,
      submitEditPrinter: 0,
      showNotesPopup: {
        show: false,
        closeButton: this.$t('popularWords.close')
      },
      clickedNotes: "",
      title: this.$t('menuitems.management.list.printers'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.printers'),
          active: true
        }
      ],
    };
  },
  validations: {

  },
  computed: {
    computedPrinters(){
      let result = [];
      let searchStr = this.searchString;

      for(let key in this.printers){
        if(searchStr == ''){
          result[result.length] = this.printers[key];
        }else{
          let isSearched = false;
          let regexp = new RegExp(`${searchStr}`, 'i');
          let serialNumber = ''+this.printers[key].serial_number +'';
          if(regexp.test(serialNumber)){
            isSearched = true;
            result[result.length] = this.printers[key];
          }
          if(!isSearched){
            let ip = ''+this.printers[key].ip +'';
            if(regexp.test(ip)){
              isSearched = true;
              result[result.length] = this.printers[key];
            }
          }
          if(!isSearched){
            let code = ''+this.printers[key].code +'';
            if(regexp.test(code)){
              isSearched = true;
              result[result.length] = this.printers[key];
            }
          }
          if(!isSearched){
            let address = ''+this.printers[key].printerLocation.address +'';
            if(regexp.test(address)){
              isSearched = true;
              result[result.length] = this.printers[key];
            }
          }
          if(!isSearched){
            let name = ''+this.printers[key].printerLocation.name +'';
            if(regexp.test(name)){
              isSearched = true;
              result[result.length] = this.printers[key];
            }
          }

        }
      }
      return result;
    }
  },
  methods: {
    closeShowNotesPopup(){
      this.showNotesPopup.show = false;
    },
    showShowNotesPopup(notes){
      this.clickedNotes = notes;
      this.showNotesPopup.show = true;
    },
    savePrinter(val){
      let formData = new FormData();
      for(let key in val){
        if( typeof val[key] === 'object'){
          formData.append(key, JSON.stringify(val[key]));
        }else{
          formData.append(key, val[key]);
        }
      }
      axios
          .post(`/v1/printers/sis-update`, formData)
          .then(() => {
            this.closeEditPopup();
            this.getPrinters();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    editPopupSubmit(){
      this.submitEditPrinter++;
    },
    showEditPopup(obj){
      let copyObj = copyArrObj.copy(obj);
      this.editPrinter = copyObj;
      this.editPrinterPopup.show = true;
    },
    closeEditPopup(){
      this.editPrinterPopup.show = false;
    },
    getPrinters(){
      let formData = new FormData();
      formData.append("selected_franchises", JSON.stringify(this.selectedFranchises));
      axios
          .post(`/v1/printers/sis-get-all`, formData)
          .then(resp => {
            this.printers = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    
  },
  created() {
    this.getPrinters();
  }
};
